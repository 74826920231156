export namespace IPageIdentifier {
  export namespace ICategoryPageIdentifier {
    /**
     * Return an instance of ICategoryPageIdentifier with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<ICategoryPageIdentifier>
    ): ICategoryPageIdentifier =>
      ({
        ...(partial ?? {}),
      }) as ICategoryPageIdentifier;
  }

  export interface ICategoryPageIdentifier {
    navigation_path: string | undefined;
  }

  export namespace ISearchQuery {
    /**
     * Return an instance of ISearchQuery with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (partial?: Partial<ISearchQuery>): ISearchQuery =>
      ({
        ...(partial ?? {}),
      }) as ISearchQuery;
  }

  export interface ISearchQuery {
    query: string | undefined;
  }

  /**
   * Return an instance of IPageIdentifier with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IPageIdentifier>): IPageIdentifier =>
    ({
      ...(partial ?? {}),
    }) as IPageIdentifier;
}

export interface IPageIdentifier {
  category_page: IPageIdentifier.ICategoryPageIdentifier | undefined;
  search_query: IPageIdentifier.ISearchQuery | undefined;
}
