import { SettingConst } from '@faire/web-api--source/types';

/**
 * Controls whether or not we use server components to render product first category page and hub page
 */
export const NEXTJS_RSC_SDUI_PRODUCT_FIRST_AND_HUB_CATEGORY_PAGE_241120 =
  'NEXTJS_RSC_SDUI_PRODUCT_FIRST_AND_HUB_CATEGORY_PAGE_241120' as SettingConst<
    'NEXTJS_RSC_SDUI_PRODUCT_FIRST_AND_HUB_CATEGORY_PAGE_241120',
    boolean,
    'RETAILER' | 'RETAILER_HEAD_TAGS' | 'VISITOR_WEB' | 'VISITOR_WEB_HEAD_TAGS'
  >;
