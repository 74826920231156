import { getWindow } from "@faire/web--source/common/globals/getWindow";

export function getRelativeEventTimestamp(
  timeStamp: DOMHighResTimeStamp | undefined
): DOMHighResTimeStamp | undefined {
  if (typeof timeStamp !== "number") {
    return undefined;
  }

  const performance = getWindow()?.performance;
  if (performance && timeStamp > performance.timeOrigin) {
    // In older browsers event.timeStamp is relative to the unix epoch.
    // In newer browsers event.timeStamp is relative to timeOrigin.
    return timeStamp - performance.timeOrigin;
  } else {
    return timeStamp;
  }
}
