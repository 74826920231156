import { SettingConst } from '@faire/web-api--source/types';

/**
 * List of product first C1 url ids to determine if we should use rsc for the category and its subcategories
 */
export const NEXTJS_RSC_PRODUCT_FIRST_CATEGORY_PAGE_ALLOWLIST =
  'NEXTJS_RSC_PRODUCT_FIRST_CATEGORY_PAGE_ALLOWLIST' as SettingConst<
    'NEXTJS_RSC_PRODUCT_FIRST_CATEGORY_PAGE_ALLOWLIST',
    Array<string>,
    'RETAILER' | 'VISITOR_WEB'
  >;
