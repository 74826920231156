import { getSettingValueWithoutAssignment } from "@faire/retailer-visitor-shared/settings/__internal__/getSetting";
import { useSetting } from "@faire/retailer-visitor-shared/settings/__internal__/useSetting";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { NEXTJS_RSC_SDUI_PRODUCT_FIRST_AND_HUB_CATEGORY_PAGE_241120 } from "@faire/web-api--source/indigofair/settings/NEXTJS_RSC_SDUI_PRODUCT_FIRST_AND_HUB_CATEGORY_PAGE_241120";

export const useSettingNextJSRscCategoryPage = () =>
  useSetting(NEXTJS_RSC_SDUI_PRODUCT_FIRST_AND_HUB_CATEGORY_PAGE_241120, false);

export const getSettingNextJSRscCategoryPage = (
  rscParam: string | undefined
): boolean => {
  if (rscParam === "2") {
    return true;
  }
  if (
    rscParam === "0" ||
    rscParam === "1" ||
    !getGlobalProperty("isNextJS", false)
  ) {
    return false;
  }
  return getSettingValueWithoutAssignment(
    NEXTJS_RSC_SDUI_PRODUCT_FIRST_AND_HUB_CATEGORY_PAGE_241120,
    false
  );
};
