import { SettingConst } from '@faire/web-api--source/types';

/**
 * List of category page navigation paths (and their children) that are eligible to render with SDUI
 */
export const WEB_SDUI_CATEGORY_PAGE_NAVIGATION_PATHS =
  'WEB_SDUI_CATEGORY_PAGE_NAVIGATION_PATHS' as SettingConst<
    'WEB_SDUI_CATEGORY_PAGE_NAVIGATION_PATHS',
    Array<string>,
    'RETAILER' | 'RETAILER_HEAD_TAGS' | 'VISITOR_WEB' | 'VISITOR_WEB_HEAD_TAGS'
  >;
