import { PageInitialized } from "@faire/retailer-visitor-shared/events";
import { setTimeoutIfPossible } from "@faire/retailer-visitor-shared/lib/async-functions/setTimeoutIfPossible";
import { getReleaseSha } from "@faire/retailer-visitor-shared/serialized-data/getReleaseSha";
import { getReleaseVersion } from "@faire/retailer-visitor-shared/serialized-data/getReleaseVersion";
import { getRetailer } from "@faire/retailer-visitor-shared/serialized-data/getRetailer";
import {
  pageLoadDurations,
  PageLoadEvent,
} from "@faire/retailer-visitor-shared/services/performance/const";
import {
  getCurrentUrl,
  getEventStartTime,
  waitForFrameDelayBelowThreshold,
} from "@faire/retailer-visitor-shared/services/performance/lib";
import {
  emitDatadogTiming,
  getDD_RUM,
} from "@faire/web--source/common/datadog/rum";
import { getDocumentOrThrow } from "@faire/web--source/common/globals/getDocument";
import {
  getWindow,
  getWindowOrThrow,
} from "@faire/web--source/common/globals/getWindow";
import { HistoryTiming } from "@faire/web--source/common/performance/HistoryTiming";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import {
  enumValues,
  protoEntries,
} from "@faire/web--source/common/typescriptUtils";
import postPerformanceReport from "@faire/web-api--source/endpoints/www/api/performance/post";

export class PerformanceMarker {
  static get = singletonGetter(PerformanceMarker);

  private recordedEvents: Set<PageLoadEvent> = new Set<PageLoadEvent>();
  private reportedToDatadog: Set<PageLoadEvent> = new Set<PageLoadEvent>();
  private initialUrl = getCurrentUrl();
  private logPageLoadEvent = require("debug")("IF:PERF");

  // unused-class-members-ignore-next
  init = () => {
    PageInitialized.subscribe(this.onPageInitialized);
    getDocumentOrThrow().addEventListener("readystatechange", this.postReport);
  };

  mark = (event: PageLoadEvent) => {
    if (this.recordedEvents.has(event) || !HistoryTiming.get().isInitialLoad) {
      return;
    }
    if (!performance || !performance.mark) {
      return;
    }

    const entry = performance.mark(event);
    const timing = entry?.startTime ?? performance.now();
    this.logPageLoadEvent(event, timing);
    this.emitToDatadog(event, timing);
    this.recordedEvents.add(event);
  };

  /**
   * Emits any of the marks that will have occurred before DD_RUM bootstrapped to datadog retroactively.
   */
  forwardMarksToDatadog = () => {
    if (!getDD_RUM()?.addTiming) {
      return;
    }

    const marks = this.performanceMarks;

    // Send any unreported marks ( Some marks are recorded before DD_RUM loads ).
    for (const [mark, timing] of marks.entries()) {
      this.emitToDatadog(mark, timing);
    }

    // Send any unreported durations
    for (const [durationName, [startEvent, endEvent]] of protoEntries(
      pageLoadDurations
    )) {
      if (!marks.has(startEvent) || !marks.has(endEvent)) {
        continue;
      }

      const start = marks.get(startEvent) as number;
      const end = marks.get(endEvent) as number;
      this.emitToDatadog(durationName, end - start);
    }
  };

  private emitToDatadog = (mark: PageLoadEvent, timing: number) => {
    if (this.reportedToDatadog.has(mark)) {
      return;
    }

    emitDatadogTiming(mark, timing);
    this.reportedToDatadog.add(mark);
  };

  private getDatadogContext = () => getDD_RUM()?.getGlobalContext?.() ?? {};

  private get performanceMarks() {
    const marks: Map<PageLoadEvent, number> = new Map<PageLoadEvent, number>();
    for (const event of enumValues(PageLoadEvent)) {
      const markTime: number | undefined = getEventStartTime(event);
      if (markTime !== undefined) {
        marks.set(event, markTime);
      }
    }
    return marks;
  }

  private get initialLoadTiming() {
    const connectStart = performance.timing.connectStart;
    return {
      domLoading: performance.timing.domLoading - connectStart,
      domComplete: performance.timing.domComplete - connectStart,
    };
  }

  private get metadata() {
    const retailer = getRetailer();

    return {
      reportUrl: getCurrentUrl(),
      initialUrl: this.initialUrl,
      releaseSha: getReleaseSha(),
      releaseVersion: getReleaseVersion(),
      retailerToken: retailer?.token ?? "",
      type: "initial-page-load",
      viewportWidth: getWindowOrThrow().innerWidth,
      viewportHeight: getWindowOrThrow().innerHeight,
      userAgent: getWindow()?.navigator.userAgent,
      devicePixelRatio: getWindow()?.devicePixelRatio,
      ddRumContext: this.getDatadogContext(),
    };
  }

  private onPageInitialized = () => {
    // We assume that the main thread is no longer saturated
    // if it takes less than 250ms to schedule the next frame.
    waitForFrameDelayBelowThreshold(250, () =>
      this.mark(PageLoadEvent.pageInteractive)
    );
  };

  private postReport = () => {
    // only send the data once document is loaded
    if (getDocumentOrThrow().readyState === "complete") {
      setTimeoutIfPossible(() => {
        this.forwardMarksToDatadog();

        postPerformanceReport({
          ...Object.fromEntries(this.performanceMarks),
          ...this.initialLoadTiming,
          ...this.metadata,
        });
      });
    }
  };
}
