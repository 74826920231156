import { SettingConst } from '@faire/web-api--source/types';

/**
 * List of brand first url ids that are under product first category page,rsc will not be used on these categories
 */
export const NEXTJS_RSC_BRAND_FIRST_CATEGORY_PAGE_BLOCKLIST =
  'NEXTJS_RSC_BRAND_FIRST_CATEGORY_PAGE_BLOCKLIST' as SettingConst<
    'NEXTJS_RSC_BRAND_FIRST_CATEGORY_PAGE_BLOCKLIST',
    Array<string>,
    'RETAILER' | 'VISITOR_WEB'
  >;
