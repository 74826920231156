import { getDocument } from "@faire/web--source/common/globals/getDocument";
import {
  getWindow,
  getWindowOrThrow,
} from "@faire/web--source/common/globals/getWindow";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import type { History } from "history";

import { getRelativeEventTimestamp } from "./getRelativeEventTimestamp";

export class HistoryTiming {
  static get = singletonGetter(HistoryTiming);

  static timestampOfLastPopstateEvent: DOMHighResTimeStamp = 0;
  static {
    /* Statically initialize a popstate listener so that our callback is the first to be called.
     * React Router and Next Router both listen to popstate and trigger rendering of new routes.
     * If we call addEventListener("popstate", ...) in initialize(), it will fire *after* the new
     * page has already mounted, and timeOfLastRouteChangeFromEventTimestamps will not be updated
     * in time for UserPerceivedLoadTime.start() to read it.
     */

    // eslint-disable-next-line @faire-web/no-restricted-globals
    if (typeof window !== "undefined") {
      // eslint-disable-next-line @faire-web/no-restricted-globals
      const _window = window;
      _window.addEventListener(
        "popstate",
        (event) => {
          HistoryTiming.timestampOfLastPopstateEvent =
            getRelativeEventTimestamp(event.timeStamp) ??
            _window.performance.now();
        },
        { capture: true }
      );
    }
  }

  private _timeOfLastRouteChange: number = 0;

  private clickCapturePhaseLocation: string | undefined;
  private timestampOfLastClickEventAfterLocationChange: DOMHighResTimeStamp = 0;

  initialize = (history: History) => {
    history.listen(() => {
      this._timeOfLastRouteChange = Math.round(
        getWindowOrThrow().performance.now()
      );
    });

    const document = getDocument();
    const window = getWindow();
    if (document && window) {
      /* Use capture phase to intercept the click event before React Router
       * or Next Router has a chance to update the URL.
       */
      document.addEventListener(
        "click",
        () => {
          this.clickCapturePhaseLocation = window.location.toString();
        },
        { capture: true }
      );

      /* Use bubbling phase to check if this click event resulted in
       * a change to window.location. If so we assume it was a click
       * on a Link component. We use the event.timeStamp because this
       * callback will fire after React Router / Next Router has updated.
       * Using performance.now() would add 100-400ms delay caused by
       * the router and any other click handlers that ran before ours.
       */
      document.addEventListener("click", (event) => {
        const currentLocation = window.location.toString();
        if (currentLocation !== this.clickCapturePhaseLocation) {
          this.timestampOfLastClickEventAfterLocationChange =
            getRelativeEventTimestamp(event.timeStamp) ??
            window.performance.now();
        }
      });
    }
  };

  get timeOfLastRouteChangeFromEventTimestamps() {
    return Math.max(
      this.timestampOfLastClickEventAfterLocationChange,
      HistoryTiming.timestampOfLastPopstateEvent
    );
  }

  get timeOfLastRouteChange() {
    return this._timeOfLastRouteChange;
  }

  get isInitialLoad() {
    return this._timeOfLastRouteChange === 0;
  }
}
