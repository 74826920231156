import { getSettingNextJSRSCBrandFirstCategoryPageBlockList } from "@faire/retailer-visitor-shared/settings/getSettingNextJSRSCBrandFirstCategoryPageBlockList";
import { getSettingNextJSRSCProductFirstCategoryPageAllowList } from "@faire/retailer-visitor-shared/settings/getSettingNextJSRSCProductFirstCategoryPageAllowList";
import { getSettingSDUICategoryPagePathNavigationPaths } from "@faire/retailer-visitor-shared/settings/getSettingSDUICategoryPagePathNavigationPaths";

export const isRSCCategoryNavigationPath = (
  navigationPath: string
): boolean => {
  const productFirstC1CategoryUrlIds =
    getSettingNextJSRSCProductFirstCategoryPageAllowList();
  const seasonalHubUrlIds = getSettingSDUICategoryPagePathNavigationPaths();
  const brandFirstUrlIds = getSettingNextJSRSCBrandFirstCategoryPageBlockList();

  const rscCategoryUrlIds = [
    ...productFirstC1CategoryUrlIds,
    ...seasonalHubUrlIds,
  ];
  const navigationPathList = navigationPath.split("|");
  const rootUrlId = navigationPathList[0];
  const currentUrlId = navigationPathList[navigationPathList.length - 1];

  const isRSCRootCategory = rscCategoryUrlIds.some(
    (urlId) => rootUrlId === urlId
  );
  const isCurrentCategoryBrandFirst = brandFirstUrlIds.some(
    (urlId) => currentUrlId === urlId
  );

  return isRSCRootCategory && !isCurrentCategoryBrandFirst;
};
