import { request } from '@faire/web-api--source';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/performance`;
};

export const build = (
  data: Partial<Record<string, any>>,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/performance';

/**
 * Product Area: API_PERFORMANCE
 */
function execute(data: Partial<Record<string, any>>): Promise<boolean>;
function execute(
  data: Partial<Record<string, any>>,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<boolean>>;
function execute(
  data: Partial<Record<string, any>>,
  options?: EndpointOptions
): Promise<boolean>;
function execute(
  data: Partial<Record<string, any>>,
  options?: EndpointOptions
): Promise<boolean | SuccessResponse<boolean>> {
  const { route, ...requestOptions } = build(data, options);
  return request<boolean>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/performance/post';
export default execute;
