import { parseToArray } from '@faire/web-api--source/parseUtils';
import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';

export type QueryParameters = {
  filters: Array<string> | undefined;
  signIn: string | undefined;
  signUp: string | undefined;
  page: string | undefined;
  insider: string | undefined;
  sort_by: string | undefined;
  sort_order: string | undefined;
  refReqId: string | undefined;
  refType: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    parsed['filters'] = parseToArray<string>(rawParsed['filters']);

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/suppliers/{c1SuppliersUrlId}/subcategory/{c2SuppliersUrlId}"
 *
 * Product Area: SEO
 */
export const route =
  '/suppliers/:c1SuppliersUrlId/subcategory/:c2SuppliersUrlId';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/suppliers/{c1SuppliersUrlId}/subcategory/{c2SuppliersUrlId}',
  productArea: 'SEO',
  pod: 'FAIRE_POD_RETAILER_ACQUISITION',
  pillar: 'FAIRE_PILLAR_RETAILER_GROWTH',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path(
  c1SuppliersUrlId: string,
  c2SuppliersUrlId: string,
  queryParams?: Partial<QueryParameters>
) {
  const compiledPath = toPath({
    c1SuppliersUrlId,
    c2SuppliersUrlId,
  });
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (
  c1SuppliersUrlId: string,
  c2SuppliersUrlId: string,
  queryParams?: Partial<QueryParameters>
): string => {
  return (
    faireBaseUrl(3000) + path(c1SuppliersUrlId, c2SuppliersUrlId, queryParams)
  );
};

export type PathParameters = {
  c1SuppliersUrlId: string;
  c2SuppliersUrlId: string;
};

export default path;
